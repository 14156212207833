import { useEffect, useMemo, useState } from "react";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import newLogoEvocs from "src/app/asset/images/logoEvocsWhite.png";
import AvatarImage from "src/components/SharingComponents/Inputs/AvatarImage";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { activateUser } from "app/store/evocsSlice/user/userAction";
import LanguageSelect from "src/components/SharingComponents/Inputs/LanguageSelect";
import { routesNames } from "app/configs/routesNames";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import SuccessModal from "src/components/Modals/SuccessModal";
import LanguageSwitcher from "app/theme-layouts/shared-components/LanguageSwitcher";
import useRecaptchaV3 from "src/app/hooks/useRecaptchaV3";
import { checkRecaptchaV3 } from "app/store/evocsSlice/user/userAction";
import * as yup from 'yup';


// helpers/env.js
const getSiteKey = () => {
  const hostname = window.location.hostname;

  console.log('Hostname:', hostname);

  switch (hostname) {
    case "evocs.it":
      return process.env.REACT_APP_SITE_KEY_PROD;
    case "app.portinformer.cloud":
      return process.env.REACT_APP_SITE_KEY_TEST; 
    // case "localhost":
    //   return process.env.REACT_APP_SITE_KEY_TEST;
    default:
      console.warn("No site key configured for this hostname:", hostname);
      return null; // Fallback, se necessario
  }
};


const validationSchema = yup.object({
  userImage: yup.string(),
  email: yup.string().email('Invalid email address').required('Email is required'),
  username: yup.string().required('Username is required'),
  password: yup.string()
  .min(8, 'Password must be at least 8 characters long')
  .required('Password is required'),
  // password: yup.string()
  //   .required('Password is required')
  //   .min(12, 'Password must be at least 12 characters long')
  //   .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
  //   .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
  //   .matches(/\d/, 'Password must contain at least one number')
  //   .matches(/[@$!%*?&]/, 'Password must contain at least one special character'),
  language: yup.string().required('Language is required'),
});


const UserForm = ({ onRegister }) => {
  const [params] = useSearchParams();
  const defaultInputs = useMemo(() => ({
    userImage: "",
    email: params.get("email") || "",
    username: "",
    password: "",
    language: "",
  }), [params]);

  const [inputs, setInputs] = useState(defaultInputs);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({}); // New state to track touched fields
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [captchaValue, setCaptchaValue] = useState(true);
  // const siteKey = '6LdeKRwqAAAAAA6wnnkY80R5c43ws422m9vfHr6E';

  // const siteKey = getSiteKey(); // Ottieni dinamicamente il siteKey

  // if (!siteKey) {
  //   console.error("Site key not found for the current domain.");
  //   return <div>Error: Site key not configured.</div>;
  // }

  // const executeRecaptcha = useRecaptchaV3(siteKey);

  useEffect(() => {
    validationSchema.validate(inputs, { abortEarly: false })
      .then(() => {
        setErrors({});
        setDisableSubmit(!captchaValue);
      })
      .catch(err => {
        const validationErrors = {};
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
        setDisableSubmit(true);
      });
  }, [inputs, captchaValue]);

  const handleChangeInput = (key, value) => {
    setInputs(prev => ({ ...prev, [key]: value }));
    setTouched(prev => ({ ...prev, [key]: true })); // Mark field as touched
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    const secureCode = params.get("code");
    if (secureCode) {
      activateUser(
        inputs.email,
        inputs.username,
        inputs.password,
        secureCode,
        inputs.language,
        inputs.userImage
      ).then(() => {
        onRegister && onRegister();
      });
    }
  };  
  
  // const handleConfirm = async (e) => {
  //   e.preventDefault();
  //   const secureCode = params.get("code");
  //   if (secureCode) {
  //     const recaptchaToken = await executeRecaptcha('login');
  //     checkRecaptchaV3(recaptchaToken)
  //       .then(() => {
  //         activateUser(
  //           inputs.email,
  //           inputs.username,
  //           inputs.password,
  //           secureCode,
  //           inputs.language,
  //           inputs.userImage
  //         ).then(() => {
  //           onRegister && onRegister();
  //         });
  //       })
  //   }
  // };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.USER_INVITATION_VIEW);
  };

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        width: "100%",
      }}
      onSubmit={handleConfirm}
    >
      <h1 style={{ fontWeight: "bold", textAlign: "center" }}> {handleTranslate("WELCOME", true)} </h1>
      <AvatarImage
        value={inputs.userImage}
        onChange={(value) => handleChangeInput("userImage", value)}
      />
      <h2> {handleTranslate("ENTER_DETAILS")} </h2>
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        id="email"
        label="Email"
        required
        disabled
        value={inputs.email}
        helperText={errors.email ?? ''}
        error={!!errors.email && touched.email}
      />
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        id="username"
        label="Username"
        required
        value={inputs.username}
        onChange={(e) => handleChangeInput("username", e.target.value)}
        helperText={errors.username ?? ''}
        error={!!errors.username && touched.username}
      />
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        id="password"
        label="Password"
        type="password"
        autoComplete="new-password"
        required
        value={inputs.password}
        onChange={(e) => handleChangeInput("password", e.target.value)}
        helperText={errors.password ?? ''}
        error={!!errors.password && touched.password}
      />
      <LanguageSelect
        required
        value={inputs.language}
        onChange={(value) => handleChangeInput("language", value)}
        helperText={errors.language ?? ''}
        error={!!errors.language && touched.language}
      />
      <Button
        disabled={disableSubmit}
        className="custom-button dark"
        type="submit"
      >
        {handleTranslate("REGISTER")}
      </Button>
    </form>
  );
};

const UserInvitation = () => {
  const navigate = useNavigate();
  const [successModal, setSuccessModal] = useState(false);
  const [params] = useSearchParams();

  const handleRegister = () => {
    setSuccessModal(true);
  };

  const handleConfirm = () => {
    navigate(routesNames.SIGN_IN_VIEW);
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.USER_INVITATION_VIEW);
  };

  return params.get("code") ? (
    <div style={{ display: "flex", height: "100%" }}>
      <Box sx={{ position: "absolute", top: "2rem", right: "2rem" }}>
        <LanguageSwitcher textColor="#242a30" />
      </Box>
      <Box
        sx={{ background: "#242a30", width: "30%", color: "white" }}
        display={{ xs: "none", md: "block" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: "2rem",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <img
            style={{ width: "250px", marginTop: "200px" }}
            src={newLogoEvocs}
            alt="Logo"
          />
          <h1> {handleTranslate("CONFIRM_REGISTRATION")} </h1>
          <p> {handleTranslate("CALL_TO_ACTION")} </p>
        </div>
      </Box>
      <Box
        sx={{ display: "flex", alignItems: "center", padding: "1rem" }}
        width={{ xs: "100%", md: "80%" }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            gap: "1rem",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          <UserForm onRegister={handleRegister} />
        </div>
      </Box>
      <SuccessModal
        isOpen={successModal}
        title={handleTranslate("REGISTRATION_SUCCESS")}
        confirmButton
        confirmText={handleTranslate("GO_TO_LOGIN", true)}
        onConfirm={handleConfirm}
      />
    </div>
  ) : (
    <Navigate to={routesNames.SIGN_IN_VIEW} />
  );
};

export default UserInvitation;

